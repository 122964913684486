<template>
  <div>
    <headers />
      <div class="mb row" style="padding:15px;padding-top: 90px !important;">
    </div>
    <div class="row">
     
        <div class="tab-content" style="width:100%;background:#fff">
          <h4 class="header-title">{{lan.history_leases_search}}</h4>
          <router-link :to="{path:tolink}" class="btn btn-primary waves-effect waves-light btn-back-fixed">
          <i class="mdi mdi-arrow-left-bold"></i> 一覧
          </router-link>
          
            <div class="embed-responsive-16by9 tab-pane show active tc4" >
                <div class="col-12">
                  <div class="card-box">
                    <list-article :viewParams="viewParams" />
                  </div>
                </div>
            </div>
          
         
          </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import ListArticle from '@/components/ListArticle.vue'

import router from '@/router'
import Headers from '@/components/Headers.vue'
export default {
  name: 'CorpDetail',
  components: {
    ListArticle,
    Headers
  },
  data() {
    const { currentRoute } = router
    return {
     
      tolink:"/"+currentRoute.value.query.tolink,
      viewParams: {
        name: 'movingCasesInfo',
        apiUrl: '/v1/movingCaseInfo/' + currentRoute.value.query.dataId,
        paramKey: 'ID',
      },
      cur_lan:localStorage.getItem("cur_lan"),
      lan:inject('global').language_data,lan_help:inject('global').language_help_data
    } 	
  },
  mounted() {
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
   

  },
  methods: {
    goto(corp_number){
      console.log('corp_number'+corp_number)
    },
    
    
   
  },

 
}
</script>
<style lang="scss">

.btn-back {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 255;
}

.build-article .card-box {
  max-height: 1460px;
  overflow-y: auto;
}

.build-article .row > div {
  display: flex;
  flex-direction: column;
}

.build-article .card-box {
  flex: 1;
}

// rader-chart
.build-article .embed-responsive-1by1 {
  max-height: 550px;
}

.build-article .row .btn-toolbar {
  flex-direction: row;
}

.other-comments-container {
  position: relative;
}

.other-item {
  text-align: left;
  display: block;
  flex: 1;
  height: 230px;
  overflow-y: auto;
  padding: .5rem 1rem;
  word-break: break-all;
}

.other-control {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem .25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: .15s;
}

.other-control-prev {
  left: -1.5rem;
}

.other-control-next {
  right: -1.5rem;
}
.piclist .carousel{
  height: 640px;
  width: 90%;
}
.carousel__prev, .carousel__next{
  background: #012a2d;
}
.carousel__pagination-button--active{
  color: #012a2d;
  background: #012a2d;
}
.carousel__slide{margin: 0 auto;width: 100% !important;}
.table-borderless tr{border-bottom: 1px solid #ddd;}
.carousel__slide img{
  max-width: 100%;
  max-height: 100%;
}
.responsive-table-plugin tbody th{
  width: 25%;
  font-weight: bolder !important;
}
.market_url{width: 100%;height: 800px;border: 0;}
</style>